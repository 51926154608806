/* eslint-disable react/no-danger */
import { ReactElement, useEffect } from "react";
import { Button, Dropdown, Empty, Modal, Typography, message } from "antd";
import { BudgetEventInfoType } from "app/types/budget/budget.types";
import { ChevronDownOutlined } from "assets/icons/icons";
import { detectChangedNodes } from "app/utils/helpers/detect_changed_nodes";
import { budgetEventValidationSchema } from "app/lib/validation_schemas/budget_event_validation.schema";
import { formatNumber } from "app/utils/helpers/format_number";
import { useUpdateBudgetEvent } from "app/utils/api/mutations/budget.mutation";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import EventNode from "./event_node";
import "app/components/modules/budget/edit_event_modal/edit_event_modal.scss";

const EditEventModal = ({
    event,
    isVisible,
    closeModalHandler,
    racePk,
    otherEvents,
}: {
    event: Partial<BudgetEventInfoType> | undefined;
    isVisible: boolean;
    racePk: number;
    closeModalHandler: () => void;
    otherEvents: BudgetEventInfoType[];
}): ReactElement => {
    const { mutate: updateBudgetItem, isPending: updateBudgetItemLoading } = useUpdateBudgetEvent({
        onSuccess: () => {
            message.success(`${event?.name} event updated successfully`);
        },

        onError: () => {
            message.error("An error occured");
        },
    });

    const { mutate: updateAndCloseBudgetItem, isPending: updateAndCloseBudgetItemLoading } =
        useUpdateBudgetEvent({
            onSuccess: () => {
                message.success(`${event?.name} event updated successfully`);
                closeModalHandler();
            },

            onError: () => {
                message.error("An error occured");
            },
        });

    const updateEvent = (values: BudgetEventInfoType, closeOnFinish: boolean) => {
        const changedNodes = detectChangedNodes({
            oldNodes: event?.nodes || [],
            newNodes: values?.nodes,
        });

        const sanitizedNodes = changedNodes.map(({ temp_pk, ...keepAttrs }) => keepAttrs);

        const requestBody =
            event && event.pk
                ? {
                      budgetEventPk: event!.pk!,
                      budgetRacePk: racePk,
                      nodes: sanitizedNodes,
                  }
                : null;

        if (requestBody) {
            if (closeOnFinish) {
                updateAndCloseBudgetItem(requestBody);
            } else {
                updateBudgetItem(requestBody);
            }
        }
    };

    const formContext = useForm<BudgetEventInfoType>({
        resolver: yupResolver<any>(budgetEventValidationSchema),
        mode: "onTouched",
        reValidateMode: "onChange",
        defaultValues: {
            nodes: [],
        },
    });

    const {
        handleSubmit,
        reset,
        getValues,
        setValue,
        watch,
        control,
        trigger,
        formState: { isValid, errors },
    } = formContext;

    const { fields, remove, insert } = useFieldArray({
        control,
        name: "nodes",
    });

    // Auto populate form data
    useEffect(() => {
        if (event) {
            reset({ nodes: event.nodes });
        }
    }, [event, reset]);

    const nodes = watch("nodes");

    const totalParticipants = nodes.reduce((acc, curr) => acc + (curr.participants_curr || 0), 0);

    const totalParticipantsProj = nodes.reduce(
        (acc, curr) => acc + (curr.participants_proj || 0),
        0
    );

    return (
        <Modal
            open={isVisible}
            onCancel={() => {
                closeModalHandler();
            }}
            destroyOnClose
            centered
            footer={null}
            title={
                <>
                    Fee schedule
                    <Typography.Text style={{ fontWeight: 400 }}>{event?.name}</Typography.Text>
                </>
            }
            width={780}
            className="edit-event-modal"
        >
            <div className="edit-event-modal__line" />

            <form
                onSubmit={handleSubmit(
                    (data) => {
                        updateEvent(data, true);
                    },
                    (errors) => {
                        if (errors.nodes?.root?.message) {
                            message.error(errors.nodes?.root?.message);
                        }
                    }
                )}
            >
                <div className="edit-event-modal__form-container">
                    <div className="edit-event-modal__nodes-container">
                        {fields.length > 0 ? (
                            <>
                                {fields.map((node, index) => (
                                    <EventNode
                                        node={watch("nodes")[index]}
                                        index={index}
                                        setValue={setValue}
                                        remove={remove}
                                        insert={insert}
                                        key={node?.pk || node?.temp_pk}
                                        formContext={formContext}
                                    />
                                ))}

                                <div className="edit-event-modal__node edit-event-modal__node--total">
                                    <div>
                                        <Typography.Text>Total:</Typography.Text>
                                    </div>
                                    <div />
                                    <div className="edit-event-modal__node-right">
                                        <Typography.Text>
                                            {formatNumber(totalParticipants)}
                                        </Typography.Text>
                                    </div>
                                    <div className="edit-event-modal__node-right">
                                        <Typography.Text
                                            type={
                                                totalParticipantsProj === 0 ? "danger" : undefined
                                            }
                                        >
                                            {formatNumber(totalParticipantsProj)}
                                        </Typography.Text>
                                    </div>

                                    <span className="edit-event-modal__btn-placeholder" />
                                </div>
                            </>
                        ) : (
                            <Empty
                                description="Add a line to get started"
                                imageStyle={{ height: 60 }}
                                style={{ marginBottom: 10 }}
                            />
                        )}
                    </div>
                    <div className="edit-event-modal__form-actions">
                        {otherEvents.length > 0 && (
                            <Dropdown
                                menu={{
                                    items: otherEvents.map((e) => ({
                                        key: e.pk,
                                        label: (
                                            <div role="button" tabIndex={0}>
                                                {e.name}
                                            </div>
                                        ),
                                        onClick: () => {
                                            setValue(
                                                "nodes",
                                                e.nodes.map(
                                                    (
                                                        {
                                                            pk,

                                                            ...keepAttrs
                                                        },
                                                        index
                                                    ) => ({
                                                        ...keepAttrs,
                                                        entry_fee: nodes[0].entry_fee,
                                                        participants_curr: 0,
                                                        participants_proj: 0,
                                                        temp_pk: Math.random(),
                                                    })
                                                )
                                            );
                                        },
                                    })),
                                }}
                                placement="bottomLeft"
                                trigger={["click"]}
                                rootClassName="edit-event-modal__copy-periods-dropdown"
                            >
                                <Button style={{ marginRight: "auto" }} className="ant-btn-select">
                                    Copy periods
                                    <ChevronDownOutlined width={9} />
                                </Button>
                            </Dropdown>
                        )}

                        <Button
                            className="ant-btn-secondary"
                            onClick={handleSubmit(
                                (data) => {
                                    updateEvent(data, false);
                                },
                                (errors) => {
                                    if (errors.nodes?.root?.message) {
                                        message.error(errors.nodes?.root?.message);
                                    }
                                }
                            )}
                            loading={updateBudgetItemLoading}
                            disabled={updateAndCloseBudgetItemLoading}
                        >
                            Update
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={updateAndCloseBudgetItemLoading}
                            disabled={updateBudgetItemLoading}
                        >
                            Update & close
                        </Button>
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default EditEventModal;
